import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import '../assets/sass/main.scss';
import Footer from './Footer';
import SideBar from './Sidebar';

const isBrowser = typeof window !== 'undefined'

const Layout = (props) => {  
  const isHomePage = isBrowser && window.location.pathname === '/';
  const [isPreloaded, setPreloaded] = useState(isHomePage && !window.hasAnimationPlayed);
  
  useEffect(() => {
    if (isPreloaded) {
      window.hasAnimationPlayed = true;
      const timeoutId = setTimeout(() => {
        setPreloaded(false);
      }, 100);
      
      return () => clearTimeout(timeoutId);
    }
  })

  const { children, fullMenu } = props;

  return (<>
    <div
      className={
        (isPreloaded && isHomePage)
          ? 'landing main-body is-preload'
          : 'landing main-body'
      }
    >
      <div id="page-wrapper">
        <SideBar fullMenu={fullMenu} />
        {children}
        <Footer />
      </div>
    </div>
  </>);
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
