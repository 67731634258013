module.exports = {
  siteTitle: 'Tal Friedman', // <title>
  manifestName: 'Spectral',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon-rounded.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Tal',
  subHeading: 'Software Engineer, Digital Nomad<br/> Aspiring Author & Chef',
  keywords: ['webpack', 'site', 'web'],
  description: 'A website of Tal\'s personal projects',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-linkedin',
      name: 'LinkedIn',
      url: 'https://www.linkedin.com/in/talfriedmancan/'
    }
    /*{
      style: 'brands',
      icon: 'fa-github',
      name: 'Github',
      url: 'https://github.com/anubhavsrivastava',
    }*/
  ]

};
